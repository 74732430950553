// COMPONENTS
"use client";

import React, {
  createContext,
  useContext,
  useDeferredValue,
  useEffect,
  useMemo,
} from "react";
import { useSearchParams } from "next/navigation";
import { trpc } from "@/services/Trpc/client";
import { useInView } from "react-intersection-observer";
import { twMerge } from "tailwind-merge";
import {
  useDebounceValue,
  useWindowSize,
  type DebouncedState,
} from "usehooks-ts";

import TemplateListItem, { TemplateListSkeleton } from "./TemplateListItem";

const TemplateListContext = createContext<
  | {
      liveVideoPreviewData: { id: string; blank: boolean } | undefined;
      setLiveVideoPreviewData: DebouncedState<
        (value: { id: string; blank: boolean } | undefined) => void
      >;
    }
  | undefined
>(undefined);

export const useTemplateListContext = () => {
  const ctx = useContext(TemplateListContext);
  if (ctx === undefined) {
    throw new Error(
      "useTempplateListContext must be called from withint TemplateListContext Provider",
    );
  }
  return ctx;
};

const Skeletons = React.forwardRef<HTMLDivElement, { totalItemAmount: number }>(
  function ({ totalItemAmount }, ref) {
    const dims = useWindowSize({ initializeWithValue: false });
    // memoize this its slow
    return React.useMemo(() => {
      if (!dims) return null;
      const { width } = dims;
      if (!width) return;
      // this hack loads the correct amount of skeletons for tailwind breakpoints
      let amount;
      if (width > 1536) {
        amount = 5;
      } else if (width > 1280) {
        amount = 4;
      } else if (width > 1024) {
        amount = 3;
      } else {
        amount = 2;
      }

      if (totalItemAmount > 0) amount += amount - (totalItemAmount % amount);
      else amount *= 2;
      const skeletons = [];
      skeletons.length = amount;
      // this ref forwarding garbage ensures that we can load new templates when the
      // first skeleton comes into view without disrupting the layout with another div
      // this is stupid
      for (let i = 0; i < amount; i++) {
        skeletons.push(
          <TemplateListSkeleton key={i} ref={i === 0 ? ref : undefined} />,
        );
      }
      return skeletons;
    }, [totalItemAmount, ref, dims]);
  },
);

Skeletons.displayName = "Skeletons";

export const TemplateList = () => {
  // manage search params
  const searchParams = useSearchParams();
  const search = searchParams.get("search") ?? "";
  const category = searchParams.get("category") ?? undefined;
  const deferredSearch = useDeferredValue(search);

  const listOrder = useMemo(() => {
    if (category !== undefined || deferredSearch !== "") return "publishedAt";
    else return "relevance";
  }, [category, deferredSearch]);

  // fetch data
  const { hasNextPage, status, data, fetchNextPage, isFetchingNextPage } =
    trpc.cardTemplate.list.useInfiniteQuery(
      {
        limit: 15,
        tags: category ? [category] : undefined,
        textSearch: deferredSearch === "" ? undefined : search,
        visibility: "PUBLISHED",
        orderBy: listOrder,
      },
      {
        getPreviousPageParam: (firstPage) => firstPage.pagination.nextCursor,
        getNextPageParam: (lastPage) => lastPage.pagination.nextCursor,
      },
    );

  // handle infinite scroll
  const { ref, inView } = useInView();
  useEffect(() => {
    if (inView && !isFetchingNextPage) {
      fetchNextPage().catch(console.error);
    }
  }, [fetchNextPage, inView, isFetchingNextPage]);

  // handle play only 1 preview at a time
  const [liveVideoPreviewData, setLiveVideoPreviewData] = useDebounceValue<
    { id: string; blank: boolean } | undefined
  >(undefined, 150);

  const totalItemAmount = data
    ? (data.pages.length - 1) * 15 +
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      data.pages[data.pages.length - 1]!.items.length
    : 0;

  if (status === "error") return;
  if (status === "loading")
    return (
      <div className="grid grid-cols-2 gap-4 px-4 pt-2 lg:grid-cols-3 lg:gap-8 lg:px-8 lg:pt-8 xl:grid-cols-4 2xl:grid-cols-5">
        <Skeletons totalItemAmount={totalItemAmount} />
      </div>
    );

  return (
    <TemplateListContext.Provider
      value={{ liveVideoPreviewData, setLiveVideoPreviewData }}
    >
      <React.Fragment>
        <div className="grid grid-cols-2 gap-4 px-4 pt-2 lg:grid-cols-3 lg:gap-8 lg:px-8 lg:pt-8 xl:grid-cols-4 2xl:grid-cols-5">
          {data?.pages.map((page) => (
            <React.Fragment key={`page-${page.pagination.nextCursor}`}>
              {page.items.map((item) => (
                <TemplateListItem template={item} key={`template-${item.id}`} />
              ))}
            </React.Fragment>
          ))}
          {hasNextPage ? (
            <Skeletons totalItemAmount={totalItemAmount} ref={ref} />
          ) : (
            <BlankTemplates more={totalItemAmount > 0} />
          )}
        </div>
      </React.Fragment>
    </TemplateListContext.Provider>
  );
};

const BlankTemplates = ({ more }: { more: boolean }) => {
  const { ref, inView } = useInView();

  const { status, data, fetchNextPage, hasNextPage, isFetchingNextPage } =
    trpc.cardTemplate.list.useInfiniteQuery(
      {
        limit: 15,
        tags: ["blank"],
        visibility: "PUBLISHED",
      },
      {
        getPreviousPageParam: (firstPage) => firstPage.pagination.nextCursor,
        getNextPageParam: (lastPage) => lastPage.pagination.nextCursor,
      },
    );

  useEffect(() => {
    if (inView && !isFetchingNextPage) {
      fetchNextPage().catch(console.error);
    }
  }, [fetchNextPage, inView, isFetchingNextPage]);

  if (status === "error") return;

  const totalItemAmount = data
    ? (data.pages.length - 1) * 15 +
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      data.pages[data.pages.length - 1]!.items.length
    : 0;
  return (
    <React.Fragment>
      <h3
        className={twMerge(
          "text-bold col-span-full w-full px-2 text-center text-2xl",
          more ? "py-8" : "pb-8",
        )}
      >
        Sorry! We couldn&apos;t find any {more ? "more" : ""} cards with your
        current search. Below are some blank cards we think you might like.
      </h3>
      {data?.pages.map((page) => (
        <React.Fragment key={`blankpage-${page.pagination.nextCursor}`}>
          {page.items.map((item) => (
            <TemplateListItem
              isBlankListing
              template={item}
              key={`blanktemplate-${item.id}`}
            />
          ))}
        </React.Fragment>
      ))}
      {(status === "loading" || hasNextPage) && (
        <Skeletons totalItemAmount={totalItemAmount} ref={ref} />
      )}
    </React.Fragment>
  );
};

export default TemplateList;
