import React, {
  forwardRef,
  ReactNode,
  Suspense,
  useDeferredValue,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import Image from "next/image";
import {
  AssetManagerProvider,
  useAssetManager,
} from "@/remotion/core/AssetManager";
import { ReadOnlyCardObjects } from "@/remotion/core/derivedSchema";
import { loadWrapVideoData } from "@/remotion/core/loader";
import {
  useWrapVideoSnap,
  WrapVideoContextProvider,
} from "@/remotion/editor/WrapVideoContextProvider";
import { RemotionPlayerPreview } from "@/remotion/Player";
import { useS3Object } from "@/services/Amplify/hooks/getS3Object";
import { trpc, type RouterOutput } from "@/services/Trpc/client";
import { ErrorBoundary } from "@/utils/ErrorBoundary";
import DefaultCardImg from "public/images/defaultcard.png";
import { twMerge } from "tailwind-merge";
import { useDebounceValue } from "usehooks-ts";
import { useSnapshot } from "valtio/react";

import { useHomePageModalContext } from "./HomePageModal";
import { useTemplateListContext } from "./TemplateList";

const CardTemplateVideoPreview = ({
  cardObjects,
  fallback,
  openDialog,
}: {
  cardObjects: ReadOnlyCardObjects;
  fallback: ReactNode;
  openDialog: () => void;
}) => {
  const wrapVideo = useWrapVideoSnap();
  const ready = useSnapshot(useAssetManager()).loaded;

  // This ensures that the thumbnail is displayed on top of the player for 50 extra ms
  // this gives the player extra time to render itself
  // this prevents showing a frame of white while the browser renders the player
  const [showVideo, setShowVideo] = useDebounceValue(false, 50);
  useLayoutEffect(() => setShowVideo(ready), [ready]);

  // show the layout spinner after loading for 500ms
  const timeout = useRef<NodeJS.Timeout | undefined>();
  const [showSpinner, setShowSpinner] = useState(false);

  useEffect(() => {
    timeout.current = setTimeout(() => setShowSpinner(true), 500);
    return () => clearTimeout(timeout.current);
  }, []);

  if (!ready) {
    const className = twMerge(
      "h-full w-full",
      showSpinner ? "hover:cursor-progress" : "",
    );
    return <div className={className}>{fallback}</div>;
  }
  return (
    <>
      <div
        className="absolute h-full w-full overflow-hidden rounded-2xl"
        style={{
          zIndex: showVideo ? 0 : 20,
        }}
      >
        {fallback}
      </div>
      <div onClick={openDialog}>
        <RemotionPlayerPreview
          style={{ height: "100%" }}
          controls={false}
          loop
          autoPlay
          wrapVideo={wrapVideo}
          cardObjects={cardObjects}
        />
      </div>
    </>
  );
};

export const CardTemplateImagePreview: React.FC<{
  imgKey: string;
  onClick: () => void;
}> = ({ imgKey, onClick }) => {
  const { data } = useS3Object({ key: imgKey });
  const src = data || DefaultCardImg.src;

  return (
    <div
      onClick={onClick}
      className="duration-400 relative h-full max-h-full scale-100 overflow-hidden"
    >
      <Image
        src={src}
        className="-z-0 object-cover blur-lg"
        alt=""
        fill
        sizes="50vw"
      />
      <Image
        src={src}
        className="absolute z-10 object-contain"
        alt=""
        fill
        sizes="50vw"
      />
    </div>
  );
};

const CardTemplatePreview = ({
  template,
  enablePreview,
  openDialog,
}: {
  template: CardTemplate;
  enablePreview: boolean;
  openDialog: () => void;
}) => {
  const { data } = trpc.cardTemplate.getWithVideo.useQuery(
    {
      id: template.id,
    },
    {
      enabled: enablePreview,
      staleTime: Infinity,
    },
  );

  const imagePreview = useMemo(
    () => (
      <CardTemplateImagePreview
        imgKey={template.thumbnailURL}
        onClick={openDialog}
      />
    ),
    [],
  );

  if (enablePreview && data) {
    const cardObjects = loadWrapVideoData(
      data.wrapVideo.cardObjects as unknown[],
    );

    return (
      <ErrorBoundary>
        <AssetManagerProvider cardObjects={cardObjects} prefetch>
          <WrapVideoContextProvider wrapVideo={data.wrapVideo}>
            <CardTemplateVideoPreview
              openDialog={openDialog}
              cardObjects={cardObjects}
              fallback={imagePreview}
            />
          </WrapVideoContextProvider>
        </AssetManagerProvider>
      </ErrorBoundary>
    );
  }

  return imagePreview;
};

type CardTemplate = RouterOutput["cardTemplate"]["list"]["items"][number];

const TemplateListItem = ({
  template,
  isBlankListing,
}: {
  template: CardTemplate;
  isBlankListing?: boolean;
}) => {
  const showHomePageModal = useHomePageModalContext((ctx) => ctx.show);

  const showHomePageModalTemplate = useHomePageModalContext(
    (ctx) => ctx.setTemplate,
  );

  const openDialog = () => {
    showHomePageModal();
    showHomePageModalTemplate(template);
  };
  const { liveVideoPreviewData, setLiveVideoPreviewData } =
    useTemplateListContext();

  const enablePreview =
    liveVideoPreviewData?.id === template.id &&
    liveVideoPreviewData?.blank === !!isBlankListing;

  const handleMouseEnter = () => {
    if (enablePreview) return;
    setLiveVideoPreviewData({ id: template.id, blank: !!isBlankListing });
  };

  const handleMouseLeave = () => {
    setLiveVideoPreviewData(undefined);
  };

  return (
    <div
      className="group relative flex justify-center overflow-visible  ring-blue-500 hover:cursor-pointer"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {/* Content */}
      <div className="aspect-9/16 group flex max-h-[70vh] w-full flex-col  overflow-hidden rounded-2xl bg-white shadow-md  ring-white ring-offset-1 hover:shadow-lg hover:shadow-white hover:ring-4">
        <CardTemplatePreview
          template={template}
          enablePreview={enablePreview}
          openDialog={openDialog}
        />

        <div className="absolute  bottom-2 left-2 mr-2 font-medium opacity-100 transition-opacity group-hover:opacity-0">
          <div className="rounded-xl bg-white/60 p-1 px-2">{template.name}</div>
          {/* <div className="flex">
            {template.tags.map((tag) => (
              <div key={tag} className="px-2 font-normal lowercase">
                #{tag}
              </div>
            ))}
          </div> */}
        </div>
        {template.isFree && (
          <div
            className="hover:inset right-[-3%] top-4 flex items-center justify-center bg-green-600 px-[1.9rem] pb-1 font-semibold group-hover:right-[-5%]"
            style={{
              position: "absolute",
              height: 40,
              zIndex: 5,
              boxShadow: "0 -10px 0 inset #0005",
              clipPath:
                "polygon(0 0, 100% 0, 100% 90%, 90% 100%, 90% 90%, 0 90%, 10px 45%",
              transition: "right 500ms",
            }}
          >
            Free
          </div>
        )}
      </div>
    </div>
  );
};

export const TemplateListSkeleton = forwardRef<HTMLDivElement>((_, ref) => {
  return (
    <div
      ref={ref}
      className="aspect-9/16 relative  max-h-[70vh] w-full animate-pulse rounded-2xl bg-gray-300  shadow-md ring-white ring-offset-1"
    >
      <div className="absolute  bottom-2 left-2 h-[2rem] w-32 rounded-xl bg-gray-400 "></div>
    </div>
  );
});
TemplateListSkeleton.displayName = "TemplateListSkeleton";

export default TemplateListItem;
