import { createContext, useContext, useRef, type ReactNode } from "react";
import { proxy, useSnapshot } from "valtio";

import { type WrapVideo } from "@matan/database";

const WrapVideoContext = createContext<WrapVideo | undefined>(undefined);
type Props = {
  wrapVideo: WrapVideo;
  children?: ReactNode;
};

export const WrapVideoContextProvider = (props: Props) => {
  const state = useRef(proxy(props.wrapVideo)).current;
  return (
    <WrapVideoContext.Provider value={state}>
      {props.children}
    </WrapVideoContext.Provider>
  );
};

export const useWrapVideoState = () => {
  const state = useContext(WrapVideoContext);
  if (state === undefined)
    throw new Error(
      "useWrapVideo must be called from within WrapVideoProvider Component",
    );
  return state;
};

export const useWrapVideoSnap = () => {
  const state = useWrapVideoState();
  return useSnapshot(state);
};
